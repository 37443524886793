import { animated, useSpring } from "@react-spring/web";
import type { SanityDocument } from "@sanity/client";
import clsx from "clsx";
import React, { Suspense, useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";

import { Heading } from "~/components";
import { useWindowSize } from "~/hooks";

import PagingArrow from "~/assets/svg/PagingArrow";
import Quotes from "~/assets/svg/Quotes";

const maxStoryWidth = 600;

const Story = ({
  quote,
  name,
  order,
  isActive,
  handlePrevious,
  handleNext,
  title,
  total,
}: {
  quote: string;
  name: string;
  order: number;
  isActive: boolean;
  handlePrevious: () => void;
  handleNext: () => void;
  title?: string;
  total: number;
}) => {
  const isFirstOrLast = order === 0 || order === total - 1;

  const styles = useSpring({
    transform: `translateY(${isActive ? 10 : 0}%)`,
    opacity: isFirstOrLast ? 0 : isActive ? 1 : 0.5,
  });
  const { width } = useWindowSize();
  const storyWidth = Math.min(maxStoryWidth, width || 0);

  // Rotate the story when order changes
  // const rotateRef = useSpringRef();
  const [rotateProps, api] = useSpring(
    () => ({
      from: {
        transform: `rotate(0deg)`,
      },
      to: {
        transform: `rotate(8deg)`,
        left: `${order * storyWidth}px`,
      },
    }),
    [order, storyWidth]
  );

  useEffect(() => {
    api.start({
      transform: `rotate(8deg)`,
    });
    api.start({
      transform: `rotate(0deg)`,
      delay: 250,
    });
  }, [order, storyWidth, api]);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleNext();
    },
    onSwipedRight: () => {
      handlePrevious();
    },
  });

  return (
    <animated.div
      className="absolute px-6"
      style={{
        willChange: "transform",
        ...rotateProps,
        maxWidth: `${storyWidth}px`,
        width: `${storyWidth}px`,
      }}
      {...handlers}
      itemScope
      itemType="https://schema.org/Review"
    >
      <animated.div
        className="rounded-[20px] bg-gray-lightest px-10 py-5 2xl:rounded-[50px] 2xl:p-14"
        style={{
          ...styles,
        }}
      >
        <div className="mb-4 w-12 2xl:mb-6 2xl:w-28">
          <div
            itemProp="itemReviewed"
            itemScope
            itemType="https://schema.org/SoftwareApplication"
            className="hidden"
          >
            <span itemProp="name">Zest Cooking</span>
            <span itemProp="identifier">https://zestapp.co</span>
          </div>
          <Quotes
            width="100%"
            height="auto"
          />
          <span
            itemProp="reviewRating"
            itemScope
            itemType="https://schema.org/Rating"
            className="hidden"
          >
            <span itemProp="ratingValue">5</span>
          </span>
        </div>
        {title && (
          <p
            className="mb-2 text-left font-bold text-lg 2xl:text-2xl"
            itemProp="name"
          >
            {title}
          </p>
        )}
        <p
          className="mb-6 text-left font-apercu text-lg 2xl:mb-12 2xl:text-2xl"
          itemProp="reviewBody"
        >
          {quote}
        </p>
        <p
          className="text-left font-bold text-lg 2xl:text-xl"
          itemProp="author"
          itemScope
          itemType="https://schema.org/Person"
        >
          <span itemProp="name">{name}</span>
        </p>
      </animated.div>
    </animated.div>
  );
};

const StorySkeleton = ({ quote, name }: { quote: string; name: string }) => {
  return (
    <div
      className="invisible px-6"
      style={{
        width: `${maxStoryWidth}px`,
      }}
    >
      <div className="bg-gray-lightest 2xl:rounded-[50px] 2xl:p-14">
        <div className="mb:2 w-12 2xl:mb-6 2xl:w-28">
          <Quotes width="100%" />
        </div>
        <p className="text-left font-apercu text-lg 2xl:mb-12 2xl:text-2xl">
          {quote}
        </p>
        <p className="text-left font-bold text-lg 2xl:text-xl">{name}</p>
      </div>
    </div>
  );
};

const Stories = ({ story }: { story: SanityDocument[] }) => {
  const { width } = useWindowSize();
  const storyWidth = Math.min(maxStoryWidth, width || 0);
  const carouselWidth = storyWidth * story.length;
  const [currentStory, setCurrentStory] = useState(
    Math.floor(story.length / 2)
  );

  const [orders, setOrders] = useState(Array.from(Array(story.length).keys()));

  const defaultTransformX =
    storyWidth * Math.floor(story.length / 2) -
    (width || 0) / 2 +
    storyWidth / 2;

  const [styles] = useSpring(
    () => ({
      to: {
        transform: `translateX(-${defaultTransformX}px)`,
      },
    }),
    [width]
  );

  const handlePrevious = () => {
    // Shift the first element and push it to the end
    const firstElement = orders.shift();
    setOrders([...orders, firstElement as number]);

    if (currentStory === 0) {
      setCurrentStory(story.length - 1);
      return;
    } else {
      setCurrentStory(currentStory - 1);
    }
  };

  const handleNext = () => {
    // Pop the last element and unshift it to the front
    const lastElement = orders.pop();
    setOrders([lastElement as number, ...orders]);

    if (currentStory === story.length - 1) {
      setCurrentStory(0);
      return;
    } else {
      setCurrentStory(currentStory + 1);
    }
  };

  const totalStories = story.length;

  return (
    <section className={clsx(["py-12", "2xl:py-24"])}>
      <Heading
        centered
        className="mb-10"
      >
        Join 500,000+ others
      </Heading>
      {/* Pagination Dots - only on mobile */}
      <div className="flex translate-y-[15px] justify-center sm:hidden">
        {story.map((s, index) => {
          return (
            <div
              key={index}
              className={clsx([
                "mx-1 h-2 w-2 rounded-full transition-colors",
                currentStory === index ? "bg-gold-main" : "bg-gray-lightest",
              ])}
            />
          );
        })}
      </div>
      <div test-id="carousel">
        <animated.div
          test-id="carousel-container relative"
          className="mb-20 flex items-stretch sm:mb-0"
          style={{
            width: `${carouselWidth}px`,
            ...styles,
          }}
        >
          {story.map((s, index) => {
            return (
              <Story
                key={index}
                quote={s.review}
                name={s.person}
                order={orders[index]}
                isActive={index === currentStory}
                handlePrevious={handlePrevious}
                handleNext={handleNext}
                title={s.title}
                total={totalStories}
              />
            );
          })}
          {story.map((s, index) => {
            return (
              <StorySkeleton
                key={index}
                quote={s.review}
                name={s.person}
              />
            );
          })}
        </animated.div>
      </div>
      <div className="container relative z-10 my-24 hidden sm:flex">
        <button
          onClick={handlePrevious}
          className="ml-auto px-6 transition-opacity hover:opacity-80"
        >
          <PagingArrow />
        </button>
        <button
          onClick={handleNext}
          className="rotate-180 px-6 transition-opacity hover:opacity-80"
        >
          <PagingArrow />
        </button>
      </div>
    </section>
  );
};

export default function SuspenseStories({
  story,
}: {
  story: SanityDocument[];
}) {
  return (
    <Suspense fallback={null}>
      <Stories story={story} />
    </Suspense>
  );
}
