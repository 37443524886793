import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={130}
    height={130}
    fill="none"
    viewBox="0 0 130 130"
    preserveAspectRatio="meet"
    {...props}
  >
    <path
      fill="#FF8811"
      fillRule="evenodd"
      d="M100.842 51.025c12.751 2.47 22.387 13.704 22.387 27.17 0 15.275-12.399 27.674-27.674 27.674s-27.673-12.4-27.673-27.674c0-20.356 6.857-32.998 14.906-40.901 12.323-12.101 27.755-13.152 27.755-13.152a4.056 4.056 0 0 1 3.83 2.064 4.046 4.046 0 0 1-.228 4.338s-7.334 10.325-11.905 17.962a56.407 56.407 0 0 0-1.398 2.519ZM39.731 51.025c12.751 2.47 22.387 13.704 22.387 27.17 0 15.275-12.398 27.674-27.673 27.674-15.275 0-27.674-12.4-27.674-27.674 0-20.356 6.857-32.998 14.907-40.901C34 25.193 49.433 24.142 49.433 24.142a4.056 4.056 0 0 1 3.83 2.064 4.058 4.058 0 0 1-.228 4.338S45.7 40.87 41.129 48.507a56.6 56.6 0 0 0-1.398 2.519Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
